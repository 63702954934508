// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getPackages = createAsyncThunk('appNoovusCloud/getPackages', async () => {
    const res = await axios.get('/user/packages')
    return res.data
})

export const appNoovusCloudSlice = createSlice({
    name: 'appNoovusCloud',
    initialState: {
        packages: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getPackages.fulfilled, (state, action) => {
            state.packages = action.payload
        })
    }
})

export default appNoovusCloudSlice.reducer
