// ** Reducers Imports
import layout from './layout'
import navbar from './navbar'
import auth from './authentication'

import calendar from '@src/views/apps/calendar/store'
import chat from '@src/views/apps/chat/store'
import activities from '@src/views/pages/activities/store'
import noovusCloud from '@src/views/apps/noovusCloud/store'

const rootReducer = { navbar, layout, auth, calendar, chat, activities, noovusCloud }

export default rootReducer
