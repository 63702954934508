import mock from '../mock'

const PAGE_SIZE = 10
const RESPONSE_DELAY = 600 //ms

const searchDataUsers = [
    {
        userId: 1,
        img: require('@src/assets/images/profile/user-uploads/user-03.jpg').default,
        username: 'Kunigunde Weiß',
        fans: Math.floor(Math.random() * 10000),
        photos: Math.floor(Math.random() * 100),
        videos: Math.floor(Math.random() * 20)
    },
    {
        userId: 2,
        img: require('@src/assets/images/profile/user-uploads/user-03.jpg').default,
        username: 'Helma Stumpf',
        fans: Math.floor(Math.random() * 10000),
        photos: Math.floor(Math.random() * 100),
        videos: Math.floor(Math.random() * 20)
    },
    {
        userId: 3,
        img: require('@src/assets/images/profile/user-uploads/user-03.jpg').default,
        username: 'Juliane Siegel',
        fans: Math.floor(Math.random() * 10000),
        photos: Math.floor(Math.random() * 100),
        videos: Math.floor(Math.random() * 20)
    },
    {
        userId: 4,
        img: require('@src/assets/images/profile/user-uploads/user-03.jpg').default,
        username: 'Ermelinda Schmid',
        fans: Math.floor(Math.random() * 10000),
        photos: Math.floor(Math.random() * 100),
        videos: Math.floor(Math.random() * 20)
    },
    {
        userId: 5,
        img: require('@src/assets/images/profile/user-uploads/user-03.jpg').default,
        username: 'Anneliese Beck',
        fans: Math.floor(Math.random() * 10000),
        photos: Math.floor(Math.random() * 100),
        videos: Math.floor(Math.random() * 20)
    },
    {
        userId: 6,
        img: require('@src/assets/images/profile/user-uploads/user-03.jpg').default,
        username: 'Agneth Protz',
        fans: Math.floor(Math.random() * 10000),
        photos: Math.floor(Math.random() * 100),
        videos: Math.floor(Math.random() * 20)
    },
    {
        userId: 7,
        img: require('@src/assets/images/profile/user-uploads/user-03.jpg').default,
        username: 'Sigi Fischer',
        fans: Math.floor(Math.random() * 10000),
        photos: Math.floor(Math.random() * 100),
        videos: Math.floor(Math.random() * 20)
    },
    {
        userId: 8,
        img: require('@src/assets/images/profile/user-uploads/user-03.jpg').default,
        username: 'Gretel Hoffmann',
        fans: Math.floor(Math.random() * 10000),
        photos: Math.floor(Math.random() * 100),
        videos: Math.floor(Math.random() * 20)
    },
    {
        userId: 9,
        img: require('@src/assets/images/profile/user-uploads/user-03.jpg').default,
        username: 'Salida Schulz',
        fans: Math.floor(Math.random() * 10000),
        photos: Math.floor(Math.random() * 100),
        videos: Math.floor(Math.random() * 20)
    },
    {
        userId: 10,
        img: require('@src/assets/images/profile/user-uploads/user-03.jpg').default,
        username: 'Käthe Baasch',
        fans: Math.floor(Math.random() * 10000),
        photos: Math.floor(Math.random() * 100),
        videos: Math.floor(Math.random() * 20)
    },
    {
        userId: 11,
        img: require('@src/assets/images/profile/user-uploads/user-03.jpg').default,
        username: 'Anne-Marie Arbeit',
        fans: Math.floor(Math.random() * 10000),
        photos: Math.floor(Math.random() * 100),
        videos: Math.floor(Math.random() * 20)
    },
    {
        userId: 12,
        img: require('@src/assets/images/profile/user-uploads/user-03.jpg').default,
        username: 'Bertha Althaus',
        fans: Math.floor(Math.random() * 10000),
        photos: Math.floor(Math.random() * 100),
        videos: Math.floor(Math.random() * 20)
    },
    {
        userId: 13,
        img: require('@src/assets/images/profile/user-uploads/user-03.jpg').default,
        username: 'Kinge Ahlers',
        fans: Math.floor(Math.random() * 10000),
        photos: Math.floor(Math.random() * 100),
        videos: Math.floor(Math.random() * 20)
    },
    {
        userId: 14,
        img: require('@src/assets/images/profile/user-uploads/user-03.jpg').default,
        username: 'Elisa Jäger',
        fans: Math.floor(Math.random() * 10000),
        photos: Math.floor(Math.random() * 100),
        videos: Math.floor(Math.random() * 20)
    },
    {
        userId: 15,
        img: require('@src/assets/images/profile/user-uploads/user-03.jpg').default,
        username: 'Kunigunde Weiß',
        fans: Math.floor(Math.random() * 10000),
        photos: Math.floor(Math.random() * 100),
        videos: Math.floor(Math.random() * 20)
    },
    {
        userId: 16,
        img: require('@src/assets/images/profile/user-uploads/user-03.jpg').default,
        username: 'Helma Stumpf',
        fans: Math.floor(Math.random() * 10000),
        photos: Math.floor(Math.random() * 100),
        videos: Math.floor(Math.random() * 20)
    },
    {
        userId: 17,
        img: require('@src/assets/images/profile/user-uploads/user-03.jpg').default,
        username: 'Juliane Siegel',
        fans: Math.floor(Math.random() * 10000),
        photos: Math.floor(Math.random() * 100),
        videos: Math.floor(Math.random() * 20)
    },
    {
        userId: 18,
        img: require('@src/assets/images/profile/user-uploads/user-03.jpg').default,
        username: 'Ermelinda Schmid',
        fans: Math.floor(Math.random() * 10000),
        photos: Math.floor(Math.random() * 100),
        videos: Math.floor(Math.random() * 20)
    },
    {
        userId: 19,
        img: require('@src/assets/images/profile/user-uploads/user-03.jpg').default,
        username: 'Anneliese Beck',
        fans: Math.floor(Math.random() * 10000),
        photos: Math.floor(Math.random() * 100),
        videos: Math.floor(Math.random() * 20)
    },
    {
        userId: 20,
        img: require('@src/assets/images/profile/user-uploads/user-03.jpg').default,
        username: 'Agneth Protz',
        fans: Math.floor(Math.random() * 10000),
        photos: Math.floor(Math.random() * 100),
        videos: Math.floor(Math.random() * 20)
    },
    {
        userId: 21,
        img: require('@src/assets/images/profile/user-uploads/user-03.jpg').default,
        username: 'Sigi Fischer',
        fans: Math.floor(Math.random() * 10000),
        photos: Math.floor(Math.random() * 100),
        videos: Math.floor(Math.random() * 20)
    },
    {
        userId: 22,
        img: require('@src/assets/images/profile/user-uploads/user-03.jpg').default,
        username: 'Gretel Hoffmann',
        fans: Math.floor(Math.random() * 10000),
        photos: Math.floor(Math.random() * 100),
        videos: Math.floor(Math.random() * 20)
    },
    {
        userId: 23,
        img: require('@src/assets/images/profile/user-uploads/user-03.jpg').default,
        username: 'Salida Schulz',
        fans: Math.floor(Math.random() * 10000),
        photos: Math.floor(Math.random() * 100),
        videos: Math.floor(Math.random() * 20)
    },
    {
        userId: 24,
        img: require('@src/assets/images/profile/user-uploads/user-03.jpg').default,
        username: 'Käthe Baasch',
        fans: Math.floor(Math.random() * 10000),
        photos: Math.floor(Math.random() * 100),
        videos: Math.floor(Math.random() * 20)
    },
    {
        userId: 25,
        img: require('@src/assets/images/profile/user-uploads/user-03.jpg').default,
        username: 'Anne-Marie Arbeit',
        fans: Math.floor(Math.random() * 10000),
        photos: Math.floor(Math.random() * 100),
        videos: Math.floor(Math.random() * 20)
    },
    {
        userId: 26,
        img: require('@src/assets/images/profile/user-uploads/user-03.jpg').default,
        username: 'Bertha Althaus',
        fans: Math.floor(Math.random() * 10000),
        photos: Math.floor(Math.random() * 100),
        videos: Math.floor(Math.random() * 20)
    },
    {
        userId: 27,
        img: require('@src/assets/images/profile/user-uploads/user-03.jpg').default,
        username: 'Kinge Ahlers',
        fans: Math.floor(Math.random() * 10000),
        photos: Math.floor(Math.random() * 100),
        videos: Math.floor(Math.random() * 20)
    },
    {
        userId: 28,
        img: require('@src/assets/images/profile/user-uploads/user-03.jpg').default,
        username: 'Elisa Jäger',
        fans: Math.floor(Math.random() * 10000),
        photos: Math.floor(Math.random() * 100),
        videos: Math.floor(Math.random() * 20)
    }
]

const searchDataPosts = [
    { creatorId: 1, img: require('@src/assets/images/examples/bicknk_stella/profile.jpeg').default },
    { creatorId: 2, img: require('@src/assets/images/examples/emilia_._rosner/profile.jpeg').default },
    { creatorId: 3, img: require('@src/assets/images/examples/nastjahale/1.jpeg').default },
    { creatorId: 4, img: require('@src/assets/images/examples/ramona_jst/profile.jpeg').default },
    { creatorId: 5, img: require('@src/assets/images/examples/bicknk_stella/profile.jpeg').default },
    { creatorId: 6, img: require('@src/assets/images/examples/emilia_._rosner/profile.jpeg').default },
    { creatorId: 7, img: require('@src/assets/images/examples/nastjahale/1.jpeg').default },
    { creatorId: 8, img: require('@src/assets/images/examples/ramona_jst/profile.jpeg').default },
    { creatorId: 9, img: require('@src/assets/images/examples/bicknk_stella/profile.jpeg').default },
    { creatorId: 10, img: require('@src/assets/images/examples/emilia_._rosner/profile.jpeg').default },
    { creatorId: 11, img: require('@src/assets/images/examples/nastjahale/1.jpeg').default },
    { creatorId: 12, img: require('@src/assets/images/examples/ramona_jst/profile.jpeg').default },
    { creatorId: 13, img: require('@src/assets/images/examples/bicknk_stella/profile.jpeg').default },
    { creatorId: 14, img: require('@src/assets/images/examples/emilia_._rosner/profile.jpeg').default },
    { creatorId: 15, img: require('@src/assets/images/examples/nastjahale/1.jpeg').default },
    { creatorId: 16, img: require('@src/assets/images/examples/ramona_jst/profile.jpeg').default },
    { creatorId: 17, img: require('@src/assets/images/examples/bicknk_stella/profile.jpeg').default },
    { creatorId: 18, img: require('@src/assets/images/examples/emilia_._rosner/profile.jpeg').default },
    { creatorId: 19, img: require('@src/assets/images/examples/nastjahale/1.jpeg').default },
    { creatorId: 20, img: require('@src/assets/images/examples/ramona_jst/profile.jpeg').default },
    { creatorId: 21, img: require('@src/assets/images/examples/bicknk_stella/profile.jpeg').default },
    { creatorId: 22, img: require('@src/assets/images/examples/emilia_._rosner/profile.jpeg').default },
    { creatorId: 23, img: require('@src/assets/images/examples/nastjahale/1.jpeg').default },
    { creatorId: 24, img: require('@src/assets/images/examples/ramona_jst/profile.jpeg').default },
    { creatorId: 25, img: require('@src/assets/images/examples/bicknk_stella/profile.jpeg').default },
    { creatorId: 26, img: require('@src/assets/images/examples/emilia_._rosner/profile.jpeg').default },
    { creatorId: 27, img: require('@src/assets/images/examples/nastjahale/1.jpeg').default },
    { creatorId: 28, img: require('@src/assets/images/examples/ramona_jst/profile.jpeg').default },
    { creatorId: 29, img: require('@src/assets/images/examples/bicknk_stella/profile.jpeg').default },
    { creatorId: 30, img: require('@src/assets/images/examples/emilia_._rosner/profile.jpeg').default },
    { creatorId: 31, img: require('@src/assets/images/examples/nastjahale/1.jpeg').default },
    { creatorId: 32, img: require('@src/assets/images/examples/ramona_jst/profile.jpeg').default },
    { creatorId: 33, img: require('@src/assets/images/examples/bicknk_stella/profile.jpeg').default },
    { creatorId: 34, img: require('@src/assets/images/examples/emilia_._rosner/profile.jpeg').default },
    { creatorId: 35, img: require('@src/assets/images/examples/nastjahale/1.jpeg').default },
    { creatorId: 36, img: require('@src/assets/images/examples/ramona_jst/profile.jpeg').default },
    { creatorId: 37, img: require('@src/assets/images/examples/bicknk_stella/profile.jpeg').default },
    { creatorId: 38, img: require('@src/assets/images/examples/emilia_._rosner/profile.jpeg').default },
    { creatorId: 39, img: require('@src/assets/images/examples/nastjahale/1.jpeg').default },
    { creatorId: 40, img: require('@src/assets/images/examples/ramona_jst/profile.jpeg').default },
    { creatorId: 41, img: require('@src/assets/images/examples/bicknk_stella/profile.jpeg').default },
    { creatorId: 42, img: require('@src/assets/images/examples/emilia_._rosner/profile.jpeg').default },
    { creatorId: 43, img: require('@src/assets/images/examples/nastjahale/1.jpeg').default },
    { creatorId: 44, img: require('@src/assets/images/examples/ramona_jst/profile.jpeg').default },
    { creatorId: 45, img: require('@src/assets/images/examples/bicknk_stella/profile.jpeg').default },
    { creatorId: 46, img: require('@src/assets/images/examples/emilia_._rosner/profile.jpeg').default },
    { creatorId: 47, img: require('@src/assets/images/examples/nastjahale/1.jpeg').default },
    { creatorId: 48, img: require('@src/assets/images/examples/ramona_jst/profile.jpeg').default },
    { creatorId: 49, img: require('@src/assets/images/examples/bicknk_stella/profile.jpeg').default },
    { creatorId: 50, img: require('@src/assets/images/examples/emilia_._rosner/profile.jpeg').default }
]

const searchDataPostsDouble = [...searchDataPosts, ...searchDataPosts.map((d) => ({ ...d, creatorId: d.creatorId + searchDataPosts.length }))]

mock.onGet('/search/posts').reply(({ params }) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            const { page /*input*/ } = params
            const items = searchDataPostsDouble.slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE)
            const result = {
                items,
                itemsFound: searchDataPostsDouble.length
            }
            resolve([200, result])
        }, RESPONSE_DELAY)
    })
})

mock.onGet('/search/users').reply(({ params }) => {
    const { page /*input*/ } = params
    const items = searchDataUsers.slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE)
    const result = {
        items,
        itemsFound: searchDataUsers.length
    }
    return [200, result]
})

mock.onGet('http://localhost:4150/search').reply(({ params }) => {
    console.log('GIT', params)
    return [200, { git: true }]
})
