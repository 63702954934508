import mock from './mock'

const data = {
    verticalNavigation: [
        {
            id: 'posts',
            title: 'Posts',
            icon: 'clone',
            iconType: 'far',
            navLink: '/apps/posts'
        },
        {
            id: 'myProfile',
            title: 'My Profile',
            icon: 'user',
            iconType: 'far',
            navLink: '/pages/myProfile'
        },
        {
            id: 'search',
            title: 'Search',
            icon: 'search',
            iconType: 'fas',
            navLink: '/apps/search'
        },
        {
            id: 'quicks',
            title: 'Quicks',
            icon: 'circle-play',
            iconType: 'far',
            navLink: '/apps/quicks'
        },
        {
            id: 'chat',
            title: 'Chat',
            icon: 'comment',
            iconType: 'far',
            navLink: '/apps/chat'
        },
        {
            id: 'saved_posts',
            title: 'Saved',
            icon: 'bookmark',
            iconType: 'far',
            navLink: '/pages/savedPosts'
        },
        {
            id: 'activities',
            title: 'Activities',
            icon: 'bell',
            iconType: 'far',
            navLink: '/pages/activities',
            badgeTextApiUrl: '/notifications/totalUnreadNotificationsCount',
            badgeColorApiUrl: '/notifications/totalUnreadNotificationsColor'
        },
        {
            id: 'more',
            title: 'More',
            icon: 'gears',
            iconType: 'fas',
            children: [
                {
                    id: 'lists',
                    title: 'Lists',
                    icon: 'list',
                    iconType: 'fas',
                    navLink: '/apps/lists'
                },
                {
                    id: 'calendar',
                    title: 'Calendar',
                    icon: 'calendar',
                    iconType: 'far',
                    navLink: '/apps/calendar'
                },
                {
                    id: 'cloud',
                    title: 'Noovus Cloud',
                    icon: 'home',
                    iconType: 'fas',
                    navLink: '/apps/noovusCloud'
                },
                {
                    id: 'werbung',
                    title: 'Advertising',
                    icon: 'home',
                    iconType: 'fas',
                    navLink: '/pages/advertisings'
                },
                {
                    id: 'konto',
                    title: 'Account',
                    icon: 'id-card',
                    iconType: 'far',
                    navLink: '/apps/account',
                    children: [
                        {
                            id: 'analytics',
                            title: 'Analytics',
                            icon: 'chart-line',
                            iconType: 'fas',
                            navLink: '/pages/analytics'
                        },
                        {
                            id: 'commissionStatistics',
                            title: 'Commission Statistics',
                            icon: 'chart-pie',
                            iconType: 'fas',
                            navLink: '/pages/commissionStatistics'
                        },
                        {
                            id: 'bank',
                            title: 'Bank',
                            icon: 'university',
                            iconType: 'fas',
                            navLink: '/pages/bank'
                        }
                    ]
                },
                {
                    id: 'settings',
                    title: 'Settings',
                    icon: 'gears',
                    iconType: 'fas',
                    navLink: '/apps/settings'
                },
                {
                    id: 'help',
                    title: 'Helpdesk',
                    icon: 'question',
                    iconType: 'fas',
                    navLink: '/apps/helpdesk'
                }
            ]
        }
    ],
    horizontalNavigation: [
        {
            id: 'posts',
            title: 'Posts',
            icon: 'clone',
            iconType: 'far',
            navLink: '/apps/posts'
        },
        {
            id: 'myProfile',
            title: 'My Profile',
            icon: 'user',
            iconType: 'far',
            navLink: '/pages/myProfile'
        },
        {
            id: 'search',
            title: 'Search',
            icon: 'search',
            iconType: 'fas',
            navLink: '/apps/search'
        },
        {
            id: 'quicks',
            title: 'Quicks',
            icon: 'images',
            iconType: 'far',
            navLink: '/apps/quicks'
        },
        {
            id: 'chat',
            title: 'Chat',
            icon: 'comment',
            iconType: 'far',
            navLink: '/apps/chat'
        },
        {
            id: 'saved_posts',
            title: 'Saved',
            icon: 'bookmark',
            iconType: 'far',
            navLink: '/pages/savedPosts'
        },
        {
            id: 'activities',
            title: 'Activities',
            icon: 'bell',
            iconType: 'far',
            navLink: '/pages/activities',
            badgeTextApiUrl: '/activities/totalUnreadActivitiesCount',
            badgeColorApiUrl: '/activities/totalUnreadActivitiesColor'
        },
        {
            id: 'more',
            title: 'More',
            icon: 'gears',
            iconType: 'fas',
            children: [
                {
                    id: 'lists',
                    title: 'Lists',
                    icon: 'list',
                    iconType: 'fas',
                    navLink: '/apps/lists'
                },
                {
                    id: 'calendar',
                    title: 'Calendar',
                    icon: 'calendar',
                    iconType: 'far',
                    navLink: '/apps/calendar'
                },
                {
                    id: 'cloud',
                    title: 'Noovus Cloud',
                    icon: 'home',
                    iconType: 'fas',
                    navLink: '/apps/noovusCloud'
                },
                {
                    id: 'werbung',
                    title: 'Advertising',
                    icon: 'home',
                    iconType: 'fas',
                    navLink: '/pages/advertisings'
                },
                {
                    id: 'konto',
                    title: 'Account',
                    icon: 'id-card',
                    iconType: 'far',
                    navLink: '/apps/account',
                    children: [
                        {
                            id: 'analytics',
                            title: 'Analytics',
                            icon: 'chart-line',
                            iconType: 'fas',
                            navLink: '/pages/analytics'
                        },
                        {
                            id: 'commissionStatistics',
                            title: 'Commission Statistics',
                            icon: 'chart-pie',
                            iconType: 'fas',
                            navLink: '/pages/commissionStatistics'
                        },
                        {
                            id: 'bank',
                            title: 'Bank',
                            icon: 'university',
                            iconType: 'fas',
                            navLink: '/pages/bank'
                        }
                    ]
                },
                {
                    id: 'settings',
                    title: 'Settings',
                    icon: 'gears',
                    iconType: 'fas',
                    navLink: '/apps/settings'
                },
                {
                    id: 'help',
                    title: 'Helpdesk',
                    icon: 'question',
                    iconType: 'fas',
                    navLink: '/apps/helpdesk'
                }
            ]
        }
    ]
}

mock.onGet('/navigation/vertical').reply(() => [200, data.verticalNavigation])
mock.onGet('/navigation/horizontal').reply(() => [200, data.horizontalNavigation])
