import axios from 'axios'

const axiosAuthApi = axios.create({
    // baseURL: 'http://noovus-main-api.test/api',
    baseURL: 'https://noovus.api.flip-the-switch.tech/api',
    headers: { Accept: 'application/json' }
})

const axiosMainApi = axios.create({
    baseURL: 'https://noovus.api.flip-the-switch.tech/api/v1',
    headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
})

const axiosMediaApi = axios.create({
    baseURL: 'https://api.media.noovus.de/'
})

const axiosPaymentApi = axios.create({
    baseURL: 'https://api.payment.noovus.de/'
})

const axiosVideoApi = axios.create({
    baseURL: 'https://api.cloudflare.com/client/v4/accounts/50618872b5c22b528033bf7f8248477e/stream/',
    headers: { Authorization: `Bearer ${process.env.REACT_APP_CLOUDFLARE_TOKEN}` }
})

const axiosFileUpload = axios.create({
    baseURL: 'https://upload.imagedelivery.net/',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8; multipart/form-data' }
})

export { axiosAuthApi, axiosMainApi, axiosMediaApi, axiosPaymentApi, axiosVideoApi, axiosFileUpload }
