import mock from '../mock'

const data = {
    stories: [
        {
            id: 1,
            image: require('@src/assets/images/examples/bicknk_stella/profile.jpeg').default,
            link: '',
            viewed: false,
            username: 'bicknk_stella'
        },
        {
            id: 2,
            image: require('@src/assets/images/examples/emilia_._rosner/profile.jpeg').default,
            link: '',
            viewed: true,
            username: 'emilia_._rosner'
        },
        {
            id: 3,
            image: require('@src/assets/images/examples/nastjahale/profile.jpeg').default,
            link: '',
            viewed: true,
            username: 'nastjahale'
        },
        {
            id: 4,
            image: require('@src/assets/images/examples/ramona_jst/profile.jpeg').default,
            link: '',
            viewed: false,
            username: 'ramona_jst'
        },
        {
            id: 1,
            image: require('@src/assets/images/examples/bicknk_stella/profile.jpeg').default,
            link: '',
            viewed: false,
            username: 'bicknk_stella_2'
        },
        {
            id: 2,
            image: require('@src/assets/images/examples/emilia_._rosner/profile.jpeg').default,
            link: '',
            viewed: true,
            username: 'emilia_._rosner_2'
        },
        {
            id: 3,
            image: require('@src/assets/images/examples/nastjahale/profile.jpeg').default,
            link: '',
            viewed: true,
            username: 'nastjahale_2'
        },
        {
            id: 4,
            image: require('@src/assets/images/examples/ramona_jst/profile.jpeg').default,
            link: '',
            viewed: false,
            username: 'ramona_jst_2'
        }
    ],
    post: [
        {
            avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
            username: 'Piere',
            postTime: '12 Dec 2018 at 1:16 AM',
            postText: 'Morgen kommt mein neues Gel Video, seit gespannt, das wird eine Session A',
            postImg: require('@src/assets/images/avatars/avatar-blank.png').default,
            likes: '1.25k',
            youLiked: true,
            comments: '1.25k',
            share: '1.25k',
            // likedUsers: [
            //     {
            //         avatar: require('@src/assets/images/portrait/small/avatar-s-1.jpg').default,
            //         username: 'Trine Lynes'
            //     },
            //     {
            //         avatar: require('@src/assets/images/portrait/small/avatar-s-2.jpg').default,
            //         username: 'Lilian Nenes'
            //     },
            //     {
            //         avatar: require('@src/assets/images/portrait/small/avatar-s-3.jpg').default,
            //         username: 'Alberto Glotzbach'
            //     },
            //     {
            //         avatar: require('@src/assets/images/portrait/small/avatar-s-5.jpg').default,
            //         username: 'George Nordic'
            //     },
            //     {
            //         avatar: require('@src/assets/images/portrait/small/avatar-s-4.jpg').default,
            //         username: 'Vinnie Mostowy'
            //     }
            // ],
            likedCount: 140,
            locked: true,
            detailedComments: [
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'Kitty Allanson',
                    comment: 'Easy & smart fuzzy search🕵🏻 functionality which enables users to search quickly.',
                    commentsLikes: 34,
                    youLiked: false
                },
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'Jackey Potter',
                    comment: 'Unlimited color🖌 options allows you to set your application color as per your branding 🤪.',
                    commentsLikes: 61,
                    youLiked: true
                }
            ]
        },
        {
            avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
            username: 'Rosa Walters',
            postTime: '11 Dec 2019 at 1:16 AM',
            postText:
                'Wonderful Machine· A well-written bio allows viewers to get to know a photographer beyond the work. This can make the difference when presenting to clients who are looking for the perfect fit.',
            postImg: require('@src/assets/images/avatars/avatar-blank.png').default,
            likes: '1.25k',
            youLiked: true,
            comments: '1.25k',
            share: '1.25k',
            // likedUsers: [
            //     {
            //         avatar: require('@src/assets/images/portrait/small/avatar-s-1.jpg').default,
            //         username: 'Kori Scargle'
            //     },
            //     {
            //         avatar: require('@src/assets/images/portrait/small/avatar-s-2.jpg').default,
            //         username: 'Florinda Mollison'
            //     },
            //     {
            //         avatar: require('@src/assets/images/portrait/small/avatar-s-3.jpg').default,
            //         username: 'Beltran Endley'
            //     },
            //     {
            //         avatar: require('@src/assets/images/portrait/small/avatar-s-5.jpg').default,
            //         username: 'Kara Gerred'
            //     },
            //     {
            //         avatar: require('@src/assets/images/portrait/small/avatar-s-4.jpg').default,
            //         username: 'Sophey Bru'
            //     }
            // ],
            likedCount: 271,
            detailedComments: [
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'Kitty Allanson',
                    comment: 'Easy & smart fuzzy search🕵🏻 functionality which enables users to search quickly.',
                    commentsLikes: 34,
                    youLiked: false
                }
            ]
        },
        {
            avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
            username: 'Charles Watson',
            postTime: '12 Dec 2019 at 1:16 AM',
            postText:
                'Wonderful Machine· A well-written bio allows viewers to get to know a photographer beyond the work. This can make the difference when presenting to clients who are looking for the perfect fit.',
            postVid: 'https://www.youtube.com/embed/6stlCkUDG_s',
            likes: '1.25k',
            youLiked: true,
            comments: '1.25k',
            share: '1.25k',
            // likedUsers: [
            //     {
            //         avatar: require('@src/assets/images/portrait/small/avatar-s-1.jpg').default,
            //         username: 'Dehlia Bolderson'
            //     },
            //     {
            //         avatar: require('@src/assets/images/portrait/small/avatar-s-2.jpg').default,
            //         username: 'De Lamy'
            //     },
            //     {
            //         avatar: require('@src/assets/images/portrait/small/avatar-s-3.jpg').default,
            //         username: 'Vallie Kingsley'
            //     },
            //     {
            //         avatar: require('@src/assets/images/portrait/small/avatar-s-5.jpg').default,
            //         username: 'Nadia Armell'
            //     },
            //     {
            //         avatar: require('@src/assets/images/portrait/small/avatar-s-4.jpg').default,
            //         username: 'Romonda Aseef'
            //     }
            // ],
            likedCount: 264,
            detailedComments: [
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'Kitty Allanson',
                    comment: 'Easy & smart fuzzy search🕵🏻 functionality which enables users to search quickly.',
                    commentsLikes: 34,
                    youLiked: false
                }
            ]
        }
    ],
    posts: [
        {
            likes: 12,
            description: 'Beschreibung des Posts',
            username: 'nastjahale',
            postTime: 'postTime',
            postImg: require('@src/assets/images/examples/nastjahale/1.jpeg').default,
            postVid: null,
            profile_url: '',
            avatar: require('@src/assets/images/examples/nastjahale/profile.jpeg').default,
            locked: true,
            commentCount: 3,
            comments: [
                {
                    message: '',
                    from: '',
                    profile_name: '',
                    likes: 0,
                    profile_image_url: require('@src/assets/images/avatars/avatar-blank.png').default
                }
            ]
        },
        {
            likes: 12,
            description: 'Beschreibung des Posts',
            username: 'ramona_jst',
            postTime: 'postTime',
            postImg: require('@src/assets/images/examples/ramona_jst/1.jpeg').default,
            postVid: null,
            profile_url: '',
            avatar: require('@src/assets/images/examples/ramona_jst/profile.jpeg').default,
            locked: true,
            commentCount: 3,
            comments: [
                {
                    message: '',
                    from: '',
                    profile_name: '',
                    likes: 0,
                    profile_image_url: require('@src/assets/images/avatars/avatar-blank.png').default
                }
            ]
        },
        {
            likes: 12,
            description: 'Beschreibung des Posts',
            username: 'nastjahale',
            postTime: 'postTime',
            postImg: require('@src/assets/images/examples/nastjahale/2.jpeg').default,
            postVid: null,
            profile_url: '',
            avatar: require('@src/assets/images/examples/nastjahale/profile.jpeg').default,
            locked: true,
            commentCount: 3,
            comments: [
                {
                    message: '',
                    from: '',
                    profile_name: '',
                    likes: 0,
                    profile_image_url: require('@src/assets/images/avatars/avatar-blank.png').default
                },
                {
                    message: '',
                    from: '',
                    profile_name: '',
                    likes: 0,
                    profile_image_url: require('@src/assets/images/avatars/avatar-blank.png').default
                }
            ]
        }
    ]
}

mock.onGet('/dashboards/default/stories').reply(() => [200, data.stories])
mock.onGet('/dashboards/default/posts').reply(() => [200, data.post])

mock.onGet('/posts/data').reply(() => [200, data.posts])
