import mock from '../mock'

const RESPONSE_DELAY = 1000
const PAGE_SIZE = 50

const quicksMock = [
    {
        id: 0,
        videoId: 'fad0addde98644df850b00a58bda6dd3',
        img: require('@src/assets/images/examples/bicknk_stella/profile.jpeg').default,
        avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
        username: 'Kitty Allanson',
        youLiked: false
    },
    {
        id: 1,
        videoId: 'fad0addde98644df850b00a58bda6dd3',
        img: require('@src/assets/images/examples/emilia_._rosner/profile.jpeg').default,
        avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
        username: 'Jackey Potter',
        youLiked: true
    },
    {
        id: 2,
        videoId: 'fad0addde98644df850b00a58bda6dd3',
        img: require('@src/assets/images/examples/nastjahale/1.jpeg').default,
        avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
        username: 'Kitty Allanson',
        youLiked: false
    },
    {
        id: 3,
        videoId: 'fad0addde98644df850b00a58bda6dd3',
        img: require('@src/assets/images/examples/bicknk_stella/profile.jpeg').default,
        avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
        username: 'Kitty Allanson',
        youLiked: false
    },
    {
        id: 4,
        videoId: 'fad0addde98644df850b00a58bda6dd3',
        img: require('@src/assets/images/examples/emilia_._rosner/profile.jpeg').default,
        avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
        username: 'Jackey Potter',
        youLiked: true
    },
    {
        id: 5,
        videoId: 'fad0addde98644df850b00a58bda6dd3',
        img: require('@src/assets/images/examples/nastjahale/1.jpeg').default,
        avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
        username: 'Kitty Allanson',
        youLiked: false
    },
    {
        id: 6,
        videoId: 'fad0addde98644df850b00a58bda6dd3',
        img: require('@src/assets/images/examples/bicknk_stella/profile.jpeg').default,
        avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
        username: 'Kitty Allanson',
        youLiked: false
    },
    {
        id: 7,
        videoId: 'fad0addde98644df850b00a58bda6dd3',
        img: require('@src/assets/images/examples/emilia_._rosner/profile.jpeg').default,
        avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
        username: 'Jackey Potter',
        youLiked: true
    },
    {
        id: 8,
        videoId: 'fad0addde98644df850b00a58bda6dd3',
        img: require('@src/assets/images/examples/nastjahale/1.jpeg').default,
        avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
        username: 'Kitty Allanson',
        youLiked: false
    },
    {
        id: 9,
        videoId: 'fad0addde98644df850b00a58bda6dd3',
        img: require('@src/assets/images/examples/bicknk_stella/profile.jpeg').default,
        avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
        username: 'Kitty Allanson',
        youLiked: false
    },
    {
        id: 10,
        videoId: 'fad0addde98644df850b00a58bda6dd3',
        img: require('@src/assets/images/examples/emilia_._rosner/profile.jpeg').default,
        avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
        username: 'Jackey Potter',
        youLiked: true
    },
    {
        id: 11,
        videoId: 'fad0addde98644df850b00a58bda6dd3',
        img: require('@src/assets/images/examples/nastjahale/1.jpeg').default,
        avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
        username: 'Kitty Allanson',
        youLiked: false
    },
    {
        id: 12,
        videoId: 'fad0addde98644df850b00a58bda6dd3',
        img: require('@src/assets/images/examples/bicknk_stella/profile.jpeg').default,
        avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
        username: 'Kitty Allanson',
        youLiked: false
    },
    {
        id: 13,
        videoId: 'fad0addde98644df850b00a58bda6dd3',
        img: require('@src/assets/images/examples/emilia_._rosner/profile.jpeg').default,
        avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
        username: 'Jackey Potter',
        youLiked: true
    },
    {
        id: 14,
        videoId: 'fad0addde98644df850b00a58bda6dd3',
        img: require('@src/assets/images/examples/nastjahale/1.jpeg').default,
        avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
        username: 'Kitty Allanson',
        youLiked: false
    },
    {
        id: 15,
        videoId: 'fad0addde98644df850b00a58bda6dd3',
        img: require('@src/assets/images/examples/bicknk_stella/profile.jpeg').default,
        avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
        username: 'Kitty Allanson',
        youLiked: false
    },
    {
        id: 16,
        videoId: 'fad0addde98644df850b00a58bda6dd3',
        img: require('@src/assets/images/examples/emilia_._rosner/profile.jpeg').default,
        avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
        username: 'Jackey Potter',
        youLiked: true
    },
    {
        id: 17,
        videoId: 'fad0addde98644df850b00a58bda6dd3',
        img: require('@src/assets/images/examples/nastjahale/1.jpeg').default,
        avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
        username: 'Kitty Allanson',
        youLiked: false
    },
    {
        id: 18,
        videoId: 'fad0addde98644df850b00a58bda6dd3',
        img: require('@src/assets/images/examples/bicknk_stella/profile.jpeg').default,
        avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
        username: 'Kitty Allanson',
        youLiked: false
    },
    {
        id: 19,
        videoId: 'fad0addde98644df850b00a58bda6dd3',
        img: require('@src/assets/images/examples/emilia_._rosner/profile.jpeg').default,
        avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
        username: 'Jackey Potter',
        youLiked: true
    },
    {
        id: 20,
        videoId: 'fad0addde98644df850b00a58bda6dd3',
        img: require('@src/assets/images/examples/nastjahale/1.jpeg').default,
        avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
        username: 'Kitty Allanson',
        youLiked: false
    },
    {
        id: 21,
        videoId: 'fad0addde98644df850b00a58bda6dd3',
        img: require('@src/assets/images/examples/bicknk_stella/profile.jpeg').default,
        avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
        username: 'Kitty Allanson',
        youLiked: false
    },
    {
        id: 22,
        videoId: 'fad0addde98644df850b00a58bda6dd3',
        img: require('@src/assets/images/examples/emilia_._rosner/profile.jpeg').default,
        avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
        username: 'Jackey Potter',
        youLiked: true
    },
    {
        id: 23,
        videoId: 'fad0addde98644df850b00a58bda6dd3',
        img: require('@src/assets/images/examples/nastjahale/1.jpeg').default,
        avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
        username: 'Kitty Allanson',
        youLiked: false
    },
    {
        id: 24,
        videoId: 'fad0addde98644df850b00a58bda6dd3',
        img: require('@src/assets/images/examples/bicknk_stella/profile.jpeg').default,
        avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
        username: 'Kitty Allanson',
        youLiked: false
    },
    {
        id: 25,
        videoId: 'fad0addde98644df850b00a58bda6dd3',
        img: require('@src/assets/images/examples/emilia_._rosner/profile.jpeg').default,
        avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
        username: 'Jackey Potter',
        youLiked: true
    },
    {
        id: 26,
        videoId: 'fad0addde98644df850b00a58bda6dd3',
        img: require('@src/assets/images/examples/nastjahale/1.jpeg').default,
        avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
        username: 'Kitty Allanson',
        youLiked: false
    },
    {
        id: 27,
        videoId: 'fad0addde98644df850b00a58bda6dd3',
        img: require('@src/assets/images/examples/bicknk_stella/profile.jpeg').default,
        avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
        username: 'Kitty Allanson',
        youLiked: false
    },
    {
        id: 28,
        videoId: 'fad0addde98644df850b00a58bda6dd3',
        img: require('@src/assets/images/examples/emilia_._rosner/profile.jpeg').default,
        avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
        username: 'Jackey Potter',
        youLiked: true
    },
    {
        id: 29,
        videoId: 'fad0addde98644df850b00a58bda6dd3',
        img: require('@src/assets/images/examples/nastjahale/1.jpeg').default,
        avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
        username: 'Kitty Allanson',
        youLiked: false
    },
    {
        id: 30,
        videoId: 'fad0addde98644df850b00a58bda6dd3',
        img: require('@src/assets/images/examples/bicknk_stella/profile.jpeg').default,
        avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
        username: 'Kitty Allanson',
        youLiked: false
    },
    {
        id: 31,
        videoId: 'fad0addde98644df850b00a58bda6dd3',
        img: require('@src/assets/images/examples/emilia_._rosner/profile.jpeg').default,
        avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
        username: 'Jackey Potter',
        youLiked: true
    },
    {
        id: 32,
        videoId: 'fad0addde98644df850b00a58bda6dd3',
        img: require('@src/assets/images/examples/nastjahale/1.jpeg').default,
        avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
        username: 'Kitty Allanson',
        youLiked: false
    },
    {
        id: 33,
        videoId: 'fad0addde98644df850b00a58bda6dd3',
        img: require('@src/assets/images/examples/bicknk_stella/profile.jpeg').default,
        avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
        username: 'Kitty Allanson',
        youLiked: false
    },
    {
        id: 34,
        videoId: 'fad0addde98644df850b00a58bda6dd3',
        img: require('@src/assets/images/examples/emilia_._rosner/profile.jpeg').default,
        avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
        username: 'Jackey Potter',
        youLiked: true
    },
    {
        id: 35,
        videoId: 'fad0addde98644df850b00a58bda6dd3',
        img: require('@src/assets/images/examples/nastjahale/1.jpeg').default,
        avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
        username: 'Kitty Allanson',
        youLiked: false
    },
    {
        id: 36,
        videoId: 'fad0addde98644df850b00a58bda6dd3',
        img: require('@src/assets/images/examples/bicknk_stella/profile.jpeg').default,
        avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
        username: 'Kitty Allanson',
        youLiked: false
    },
    {
        id: 37,
        videoId: 'fad0addde98644df850b00a58bda6dd3',
        img: require('@src/assets/images/examples/emilia_._rosner/profile.jpeg').default,
        avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
        username: 'Jackey Potter',
        youLiked: true
    },
    {
        id: 38,
        videoId: 'fad0addde98644df850b00a58bda6dd3',
        img: require('@src/assets/images/examples/nastjahale/1.jpeg').default,
        avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
        username: 'Kitty Allanson',
        youLiked: false
    },
    {
        id: 39,
        videoId: 'fad0addde98644df850b00a58bda6dd3',
        img: require('@src/assets/images/examples/bicknk_stella/profile.jpeg').default,
        avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
        username: 'Kitty Allanson',
        youLiked: false
    },
    {
        id: 40,
        videoId: 'fad0addde98644df850b00a58bda6dd3',
        img: require('@src/assets/images/examples/emilia_._rosner/profile.jpeg').default,
        avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
        username: 'Jackey Potter',
        youLiked: true
    },
    {
        id: 41,
        videoId: 'fad0addde98644df850b00a58bda6dd3',
        img: require('@src/assets/images/examples/nastjahale/1.jpeg').default,
        avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
        username: 'Kitty Allanson',
        youLiked: false
    }
]

mock.onGet('/quicks').reply(() => [200, quicksMock])

mock.onGet('/quicks').reply(({ params }) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            const { page } = params
            const items = quicksMock.slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE)
            const result = {
                items,
                itemsCount: quicksMock.length
            }
            resolve([200, result])
        }, RESPONSE_DELAY)
    })
})
