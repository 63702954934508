import mock from '../mock'

const data = {
    subscribers: {
        series: [
            {
                name: 'Subscribers',
                data: [28, 40, 36, 52, 38, 60, 55]
            }
        ],
        analyticsData: {
            subscribers: 92600
        }
    },
    monthlyTurnover: {
        series: [
            {
                name: 'Monthly-Turnover',
                data: [10, 15, 8, 15, 7, 12, 8]
            }
        ],
        analyticsData: {
            total: 97500
        }
    },
    salesBarChart: {
        sessions: 2700,
        last_days: ['Letze 20 Tage', 'Letzter Monat', 'Letztes Jahr'],
        growth: '+5.2%',
        messages_count: 10000,
        new_count: 185000,
        current_count: 400,
        lost_count: 200,
        series: [
            {
                name: 'Sessions',
                data: [75, 125, 225, 175, 125, 75, 25]
            }
        ]
    },
    messages: {
        title: 'Nachrichten',
        last_days: ['Letze 20 Tage', 'Letzter Monat', 'Letztes Jahr'],
        repliedMessages: 400,
        openMessages: 25
    }
}

mock.onGet('/user/statistics/subscribers').reply(() => [200, data.subscribers])
mock.onGet('/user/statistics/monthlyTurnover').reply(() => [200, data.monthlyTurnover])
mock.onGet('/user/statistics/salesBarChart').reply(() => [200, data.salesBarChart])
mock.onGet('/user/statistics/messages').reply(() => [200, data.messages])
