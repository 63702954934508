// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const fetchItems = createAsyncThunk('notifications/fetchItems', async (filters) => {
    const res = await axios.get('/notifications', { filters })

    const allItems = []
    res.data.map((parentItem) => {
        parentItem.items.map((item) => {
            allItems.push(item)
        })
    })

    allItems.sort((a, b) => b.timestamp.localeCompare(a.timestamp))

    return allItems
})

export const fetchFilters = createAsyncThunk('notifications/fetchFilters', async () => {
    const res = await axios.get('/notifications/filters')

    const total = res.data.reduce((total, currentItem) => (total = total + currentItem.count), 0)

    return {
        filters: res.data,
        all_items_count: total
    }
})

export const updateFilter = createAsyncThunk('notifications/updateFilter', async (filter, { dispatch, getState }) => {
    if (getState().activities.selectedFilters.includes(filter)) {
        await dispatch(fetchItems(getState().activities.selectedFilters.filter((i) => i !== filter)))
    } else {
        await dispatch(fetchItems([...getState().activities.selectedFilters, filter]))
    }
    return filter
})

export const updateAllFilters = createAsyncThunk('notifications/updateAllFilters', async (value, { dispatch }) => {
    if (value === true) {
        await dispatch(fetchItems(['Abos', 'Sales', 'Tips']))
    } else {
        await dispatch(fetchItems([]))
    }

    return value
})

export const appActivitiesSlice = createSlice({
    name: 'pageActivities',
    initialState: {
        items: [],
        filters: [],
        selectedItem: {},
        selectedFilters: null,
        all_items_count: 0
    },
    reducers: {
        selectItem: (state, action) => {
            state.selectedItem = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchItems.fulfilled, (state, action) => {
                state.items = action.payload
            })
            .addCase(fetchFilters.fulfilled, (state, action) => {
                state.filters = action.payload.filters
                state.all_items_count = action.payload.all_items_count

                if (state.selectedFilters === null) {
                    state.selectedFilters = []
                    action.payload.filters.map((filter) => {
                        state.selectedFilters.push(filter.label)
                    })
                }
            })
            .addCase(updateFilter.fulfilled, (state, action) => {
                if (state.selectedFilters.includes(action.payload)) {
                    state.selectedFilters.splice(state.selectedFilters.indexOf(action.payload), 1)
                } else {
                    state.selectedFilters.push(action.payload)
                }
            })
            .addCase(updateAllFilters.fulfilled, (state, action) => {
                const value = action.payload
                let selected = []

                if (value === true) {
                    selected = ['Abos', 'Verkäufe', 'Tips']
                } else {
                    selected = []
                }
                state.selectedFilters = selected
            })
    }
})

export default appActivitiesSlice.reducer
