import mock from '../mock'
import { paginateArray } from '../utils'

const RESPONSE_DELAY = 500

const tableData = [
    {
        id: 1,
        invoiceNo: '#1',
        date: '09/23/2016',
        amount: '23896.35 €',
        status: 'In progress'
    },
    {
        id: 2,
        invoiceNo: '#2',
        date: '05/20/2018',
        amount: '13633.69 €',
        status: 'In progress'
    },
    {
        id: 3,
        invoiceNo: '#3',
        date: '03/24/2018',
        amount: '13076.28 €',
        status: 'Paid'
    },
    {
        id: 4,
        invoiceNo: '#4',
        date: '12/03/2017',
        amount: '12336.17 €',
        status: 'In progress'
    },
    {
        id: 5,
        invoiceNo: '#5',
        date: '08/25/2017',
        amount: '10909.52 €',
        status: 'In progress'
    },
    {
        id: 6,
        invoiceNo: '#6',
        date: '06/01/2017',
        amount: '17803.80 €',
        status: 'Pending'
    },
    {
        id: 7,
        invoiceNo: '#7',
        date: '10/15/2017',
        amount: '18991.67 €',
        status: 'Issued'
    },
    {
        id: 8,
        invoiceNo: '#8',
        date: '11/05/2016',
        amount: '19230.13 €',
        status: 'Issued'
    },
    {
        id: 9,
        invoiceNo: '#9',
        date: '12/29/2018',
        amount: '11677.32 €',
        status: 'Paid'
    },
    {
        id: 10,
        invoiceNo: '#10',
        date: '04/19/2019',
        amount: '15939.52 €',
        status: 'In progress'
    },
    {
        id: 11,
        invoiceNo: '#11',
        date: '06/12/2018',
        amount: '19252.12 €',
        status: 'Cancelled'
    },
    {
        id: 12,
        invoiceNo: '#12',
        date: '12/09/2017',
        amount: '16745.47 €',
        status: 'Cancelled'
    },
    {
        id: 13,
        invoiceNo: '#13',
        date: '01/21/2016',
        amount: '15488.53 €',
        status: 'Cancelled'
    },
    {
        id: 14,
        invoiceNo: '#14',
        date: '02/01/2016',
        amount: '18442.34 €',
        status: 'In progress'
    },
    {
        id: 15,
        invoiceNo: '#15',
        date: '04/25/2018',
        amount: '13304.45 €',
        status: 'Paid'
    },
    {
        id: 16,
        invoiceNo: '#16',
        date: '12/23/2017',
        amount: '11953.08 €',
        status: 'Paid'
    },
    {
        id: 17,
        invoiceNo: '#17',
        date: '07/11/2018',
        amount: '20484.44 €',
        status: 'Pending'
    },
    {
        id: 18,
        invoiceNo: '#18',
        date: '05/25/2019',
        amount: '16871.48 €',
        status: 'Pending'
    },
    {
        id: 19,
        invoiceNo: '#19',
        date: '04/04/2019',
        amount: '19653.56 €',
        status: 'Cancelled'
    },
    {
        id: 20,
        invoiceNo: '#20',
        date: '08/03/2017',
        amount: '18303.87 €',
        status: 'Pending'
    },
    {
        id: 21,
        invoiceNo: '#21',
        date: '03/16/2019',
        amount: '15256.40 €',
        status: 'In progress'
    },
    {
        id: 22,
        invoiceNo: '#22',
        date: '07/08/2017',
        amount: '11209.16 €',
        status: 'Issued'
    },
    {
        id: 23,
        invoiceNo: '#23',
        date: '12/23/2017',
        amount: '13778.34 €',
        status: 'Paid'
    },
    {
        id: 24,
        invoiceNo: '#24',
        date: '05/26/2016',
        amount: '23081.71 €',
        status: 'Pending'
    },
    {
        id: 25,
        invoiceNo: '#25',
        date: '07/31/2019',
        amount: '13602.24 €',
        status: 'Issued'
    },
    {
        id: 26,
        invoiceNo: '#26',
        date: '04/10/2016',
        amount: '16969.63 €',
        status: 'Cancelled'
    },
    {
        id: 27,
        invoiceNo: '#27',
        date: '09/29/2018',
        amount: '23796.62 €',
        status: 'Issued'
    },
    {
        id: 28,
        invoiceNo: '#28',
        date: '07/09/2018',
        amount: '24027.81 €',
        status: 'Cancelled'
    },
    {
        id: 29,
        invoiceNo: '#29',
        date: '07/17/2016',
        amount: '13508.15 €',
        status: 'Issued'
    },
    {
        id: 30,
        invoiceNo: '#30',
        date: '08/28/2018',
        amount: '21632.30 €',
        status: 'In progress'
    },
    {
        id: 31,
        invoiceNo: '#31',
        date: '09/03/2016',
        amount: '24875.41 €',
        status: 'Paid'
    },
    {
        id: 32,
        invoiceNo: '#32',
        date: '06/02/2016',
        amount: '23888.98 €',
        status: 'In progress'
    },
    {
        id: 33,
        invoiceNo: '#33',
        date: '09/30/2016',
        amount: '14082.44 €',
        status: 'Paid'
    },
    {
        id: 34,
        invoiceNo: '#34',
        date: '04/07/2016',
        amount: '21386.52 €',
        status: 'Issued'
    },
    {
        id: 35,
        invoiceNo: '#35',
        date: '09/11/2019',
        amount: '13835.97 €',
        status: 'Cancelled'
    },
    {
        id: 36,
        invoiceNo: '#36',
        date: '03/05/2019',
        amount: '22093.91 €',
        status: 'Cancelled'
    },
    {
        id: 37,
        invoiceNo: '#37',
        date: '11/10/2018',
        amount: '24624.23 €',
        status: 'Issued'
    },
    {
        id: 38,
        invoiceNo: '#38',
        date: '11/04/2016',
        amount: '21782.82 €',
        status: 'Pending'
    },
    {
        id: 39,
        invoiceNo: '#39',
        date: '07/18/2016',
        amount: '22794.60 €',
        status: 'In progress'
    },
    {
        id: 40,
        invoiceNo: '#40',
        date: '11/04/2018',
        amount: '10872.83 €',
        status: 'Cancelled'
    },
    {
        id: 41,
        invoiceNo: '#41',
        date: '03/02/2016',
        amount: '17471.92 €',
        status: 'Paid'
    },
    {
        id: 42,
        invoiceNo: '#42',
        date: '12/10/2018',
        amount: '24719.51 €',
        status: 'Pending'
    },
    {
        id: 43,
        invoiceNo: '#43',
        date: '01/23/2019',
        amount: '11958.33 €',
        status: 'In progress'
    },
    {
        id: 44,
        invoiceNo: '#44',
        date: '09/07/2016',
        amount: '10552.43 €',
        status: 'Pending'
    },
    {
        id: 45,
        invoiceNo: '#45',
        date: '07/23/2018',
        amount: '23024.28 €',
        status: 'Cancelled'
    },
    {
        id: 46,
        invoiceNo: '#46',
        date: '02/10/2018',
        amount: '10409.90 €',
        status: 'Paid'
    },
    {
        id: 47,
        invoiceNo: '#47',
        date: '11/23/2016',
        amount: '11446.30 €',
        status: 'Cancelled'
    },
    {
        id: 48,
        invoiceNo: '#48',
        date: '04/19/2018',
        amount: '14002.31 €',
        status: 'Pending'
    },
    {
        id: 49,
        invoiceNo: '#49',
        date: '03/18/2017',
        amount: '10704.29 €',
        status: 'Pending'
    },
    {
        id: 50,
        invoiceNo: '#50',
        date: '05/13/2019',
        amount: '21813.54 €',
        status: 'Pending'
    },
    {
        id: 51,
        invoiceNo: '#51',
        date: '04/23/2019',
        amount: '18053.11 €',
        status: 'Issued'
    },
    {
        id: 52,
        invoiceNo: '#52',
        date: '04/19/2016',
        amount: '17617.08 €',
        status: 'Issued'
    },
    {
        id: 53,
        invoiceNo: '#53',
        date: '10/06/2018',
        amount: '18866.55 €',
        status: 'Paid'
    },
    {
        id: 54,
        invoiceNo: '#54',
        date: '06/15/2017',
        amount: '13722.18 €',
        status: 'Issued'
    },
    {
        id: 55,
        invoiceNo: '#55',
        date: '09/26/2018',
        amount: '15228.80 €',
        status: 'Pending'
    },
    {
        id: 56,
        invoiceNo: '#56',
        date: '01/03/2016',
        amount: '13951.96 €',
        status: 'Pending'
    },
    {
        id: 57,
        invoiceNo: '#57',
        date: '02/04/2018',
        amount: '15100.00 €',
        status: 'Cancelled'
    },
    {
        id: 58,
        invoiceNo: '#58',
        date: '04/22/2016',
        amount: '20023.52 €',
        status: 'Paid'
    },
    {
        id: 59,
        invoiceNo: '#59',
        date: '04/08/2019',
        amount: '12630.26 €',
        status: 'In progress'
    },
    {
        id: 60,
        invoiceNo: '#60',
        date: '08/13/2019',
        amount: '13870.62 €',
        status: 'Cancelled'
    },
    {
        id: 61,
        invoiceNo: '#61',
        date: '11/06/2017',
        amount: '18220.51 €',
        status: 'Issued'
    },
    {
        id: 62,
        invoiceNo: '#62',
        date: '08/05/2017',
        amount: '13999.88 €',
        status: 'Paid'
    },
    {
        id: 63,
        invoiceNo: '#63',
        date: '09/12/2019',
        amount: '11049.79 €',
        status: 'Paid'
    },
    {
        id: 64,
        invoiceNo: '#64',
        date: '08/25/2017',
        amount: '10745.32 €',
        status: 'Paid'
    },
    {
        id: 65,
        invoiceNo: '#65',
        date: '05/24/2018',
        amount: '24769.08 €',
        status: 'Pending'
    },
    {
        id: 66,
        invoiceNo: '#66',
        date: '02/27/2019',
        amount: '16053.15 €',
        status: 'Pending'
    },
    {
        id: 67,
        invoiceNo: '#67',
        date: '09/23/2017',
        amount: '23347.17 €',
        status: 'Paid'
    },
    {
        id: 68,
        invoiceNo: '#68',
        date: '11/01/2016',
        amount: '15658.40 €',
        status: 'Pending'
    },
    {
        id: 69,
        invoiceNo: '#69',
        date: '07/15/2018',
        amount: '15396.66 €',
        status: 'In progress'
    },
    {
        id: 70,
        invoiceNo: '#70',
        date: '05/22/2016',
        amount: '15823.40 €',
        status: 'Paid'
    },
    {
        id: 71,
        invoiceNo: '#71',
        date: '01/27/2019',
        amount: '19051.25 €',
        status: 'Pending'
    },
    {
        id: 72,
        invoiceNo: '#72',
        date: '01/17/2019',
        amount: '11632.84 €',
        status: 'Cancelled'
    },
    {
        id: 73,
        invoiceNo: '#73',
        date: '11/17/2017',
        amount: '16741.31 €',
        status: 'In progress'
    },
    {
        id: 74,
        invoiceNo: '#74',
        date: '09/26/2016',
        amount: '19387.76 €',
        status: 'Issued'
    },
    {
        id: 75,
        invoiceNo: '#75',
        date: '05/21/2016',
        amount: '24014.04 €',
        status: 'Issued'
    },
    {
        id: 76,
        invoiceNo: '#76',
        date: '09/30/2016',
        amount: '14493.51 €',
        status: 'Pending'
    },
    {
        id: 77,
        invoiceNo: '#77',
        date: '09/27/2016',
        amount: '24733.28 €',
        status: 'Cancelled'
    },
    {
        id: 78,
        invoiceNo: '#78',
        date: '03/28/2016',
        amount: '21922.17 €',
        status: 'Issued'
    },
    {
        id: 79,
        invoiceNo: '#79',
        date: '04/13/2017',
        amount: '16944.42 €',
        status: 'In progress'
    },
    {
        id: 80,
        invoiceNo: '#80',
        date: '05/28/2018',
        amount: '21963.69 €',
        status: 'Pending'
    },
    {
        id: 81,
        invoiceNo: '#81',
        date: '08/07/2018',
        amount: '16049.93 €',
        status: 'In progress'
    },
    {
        id: 82,
        invoiceNo: '#82',
        date: '02/11/2018',
        amount: '24137.29 €',
        status: 'Cancelled'
    },
    {
        id: 83,
        invoiceNo: '#83',
        date: '05/13/2018',
        amount: '10089.96 €',
        status: 'Cancelled'
    },
    {
        id: 84,
        invoiceNo: '#84',
        date: '11/11/2018',
        amount: '14227.10 €',
        status: 'Issued'
    },
    {
        id: 85,
        invoiceNo: '#85',
        date: '03/28/2016',
        amount: '19562.59 €',
        status: 'In progress'
    },
    {
        id: 86,
        invoiceNo: '#86',
        date: '04/25/2016',
        amount: '17178.86 €',
        status: 'Paid'
    },
    {
        id: 87,
        invoiceNo: '#87',
        date: '06/18/2018',
        amount: '15089.83 €',
        status: 'Issued'
    },
    {
        id: 88,
        invoiceNo: '#88',
        date: '01/17/2016',
        amount: '18330.72 €',
        status: 'Pending'
    },
    {
        id: 89,
        invoiceNo: '#89',
        date: '10/08/2018',
        amount: '16284.64 €',
        status: 'Pending'
    },
    {
        id: 90,
        invoiceNo: '#90',
        date: '03/15/2017',
        amount: '18523.75 €',
        status: 'Pending'
    },
    {
        id: 91,
        invoiceNo: '#91',
        date: '02/06/2017',
        amount: '17607.66 €',
        status: 'Issued'
    },
    {
        id: 92,
        invoiceNo: '#92',
        date: '02/13/2017',
        amount: '17550.18 €',
        status: 'Cancelled'
    },
    {
        id: 93,
        invoiceNo: '#93',
        date: '07/29/2019',
        amount: '18469.35 €',
        status: 'Cancelled'
    },
    {
        id: 94,
        invoiceNo: '#94',
        date: '07/04/2019',
        amount: '24866.66 €',
        status: 'Cancelled'
    },
    {
        id: 95,
        invoiceNo: '#95',
        date: '09/27/2018',
        amount: '19586.23 €',
        status: 'Pending'
    },
    {
        id: 96,
        invoiceNo: '#96',
        date: '04/16/2017',
        amount: '19366.53 €',
        status: 'Cancelled'
    },
    {
        id: 97,
        invoiceNo: '#97',
        date: '05/20/2018',
        amount: '16619.40 €',
        status: 'Cancelled'
    },
    {
        id: 98,
        invoiceNo: '#98',
        date: '04/09/2019',
        amount: '15211.60 €',
        status: 'In progress'
    },
    {
        id: 99,
        invoiceNo: '#99',
        date: '01/26/2017',
        amount: '23704.82 €',
        status: 'Cancelled'
    },
    {
        id: 100,
        invoiceNo: '#100',
        date: '04/15/2017',
        amount: '24973.48 €',
        status: 'In progress'
    }
]

mock.onGet('/bank/payments').reply(() => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve([
                200,
                {
                    pending: '4.000.001 €',
                    readyToPay: '4.000 €'
                }
            ])
        }, RESPONSE_DELAY)
    })
})

mock.onGet('/bank/table').reply((config) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            const { q = '', perPage = 10, page = 1 } = config

            const queryLowered = q.toLowerCase()
            const filteredData = tableData.filter(
                (item) =>
                    /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
                    item.invoiceNo.toLowerCase().includes(queryLowered) ||
                    item.date.toLowerCase().includes(queryLowered) ||
                    item.amount.toLowerCase().includes(queryLowered) ||
                    item.status.toLowerCase().includes(queryLowered)
            )

            resolve([
                200,
                {
                    allData: tableData,
                    data: paginateArray(filteredData, perPage, page),
                    total: filteredData.length
                }
            ])
        }, RESPONSE_DELAY)
    })
})
