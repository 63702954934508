import mock from '../mock'

const RESPONSE_DELAY = 200

const randomDate = () => {
    const startDate = new Date(2021, 1, 1)
    const endDate = new Date(2022, 12, 31)
    const random = (endDate.getTime() - startDate.getTime()) * Math.random()
    return new Date(startDate.getTime() + random).toISOString().split('T')[0]
}

const randomSize = () => Math.random() * 10000000

const data = {
    packages: [
        {
            id: 1,
            name: 'Paket Name',
            preview_image: require('@src/assets/images/examples/nastjahale/3.jpeg').default,
            text: 'Ohhhhhh Fuck Ihr müsst ihr unbedingt folgen 🥵 @emilia_rosner .....sie hat auch zufällig grade ein mega heißes angebot für euch 🔞💦',
            images: 1,
            videos: 0,
            amount: '24€',
            send_count: 4800,
            viewed_count: 1230,
            buyed: 58,
            date_time: '25. Juni 2022, 15:00 Uhr',
            files: [
                { name: 'file1.jpeg', url: require('@src/assets/images/examples/nastjahale/1.jpeg').default },
                { name: 'file2.jpeg', url: require('@src/assets/images/examples/nastjahale/2.jpeg').default },
                { name: 'file3.jpeg', url: require('@src/assets/images/examples/nastjahale/3.jpeg').default },
                { name: 'file3.jpeg', videoId: 'fad0addde98644df850b00a58bda6dd3' },
                { name: 'file1.jpeg', url: require('@src/assets/images/examples/nastjahale/1.jpeg').default },
                { name: 'file2.jpeg', url: require('@src/assets/images/examples/nastjahale/2.jpeg').default },
                { name: 'file3.jpeg', url: require('@src/assets/images/examples/nastjahale/3.jpeg').default },
                { name: 'file3.jpeg', videoId: 'fad0addde98644df850b00a58bda6dd3' }
            ]
        },
        {
            id: 2,
            name: 'Package 2',
            preview_image: require('@src/assets/images/examples/nastjahale/5.jpeg').default,
            text: 'Für alle die es auf dem Paid Kanal verpasst haben....Wollt ihr sehen wie @nastjahale @nastjahale.free und ich es uns gegenseitig richtig besorgen ? 🥵 Wie wir uns gegenseitig Fingern und lecken bis wir kommen ?💦 Es gibt Lack, Leder und hohe Schuhe und wir verwöhnen unsere Füße mal so richtig 👄 Unbedingt anschauen denn so in Aktion hast du uns zwei noch nicht gesehen 🥵🥵🥵',
            images: 1,
            videos: 0,
            amount: '10€',
            send_count: 50,
            viewed_count: 26,
            buyed: 10,
            date_time: '26. Juni 2022, 18:00 Uhr',
            files: [
                { name: 'file1.jpeg', url: require('@src/assets/images/examples/nastjahale/1.jpeg').default },
                { name: 'file2.jpeg', url: require('@src/assets/images/examples/nastjahale/2.jpeg').default },
                { name: 'file3.jpeg', url: require('@src/assets/images/examples/nastjahale/3.jpeg').default },
                { name: 'file3.jpeg', videoId: 'fad0addde98644df850b00a58bda6dd3' }
            ]
        }
    ],
    folders: [
        {
            title: 'Ordner 1',
            subtitle: '',
            children: []
        },
        {
            title: 'Ordner 2',
            subtitle: '',
            children: []
        },
        {
            title: 'Ordner 3',
            subtitle: '',
            children: []
        },
        {
            title: 'Ordner 4',
            subtitle: '',
            children: []
        }
    ]
}

const filesData = {
    fileTree: [
        {
            name: 'Folder with folders',
            size: randomSize(),
            created: randomDate(),
            modified: randomDate(),
            accessed: randomDate(),
            isDir: true,
            children: [
                {
                    name: 'Go deeper',
                    size: randomSize(),
                    created: randomDate(),
                    modified: randomDate(),
                    accessed: randomDate(),
                    isDir: true,
                    children: [
                        {
                            name: 'Folder 1',
                            size: randomSize(),
                            created: randomDate(),
                            modified: randomDate(),
                            accessed: randomDate(),
                            isDir: true,
                            children: []
                        },
                        {
                            name: 'Folder 2',
                            size: randomSize(),
                            created: randomDate(),
                            modified: randomDate(),
                            accessed: randomDate(),
                            isDir: true,
                            children: []
                        },
                        {
                            name: 'Folder 3',
                            size: randomSize(),
                            created: randomDate(),
                            modified: randomDate(),
                            accessed: randomDate(),
                            isDir: true,
                            children: []
                        }
                    ]
                },
                {
                    name: 'Another inside folder',
                    size: randomSize(),
                    created: randomDate(),
                    modified: randomDate(),
                    accessed: randomDate(),
                    isDir: true,
                    children: []
                },
                {
                    name: 'More nested folders',
                    size: randomSize(),
                    created: randomDate(),
                    modified: randomDate(),
                    accessed: randomDate(),
                    isDir: true,
                    children: []
                },
                {
                    name: 'bicknk_stella-profile.jpg',
                    url: require('@src/assets/images/examples/bicknk_stella/profile.jpeg').default,
                    size: 1987654987,
                    created: randomDate(),
                    modified: randomDate(),
                    accessed: randomDate()
                },
                {
                    name: 'emilia_._rosner-profile.jpg',
                    url: require('@src/assets/images/examples/emilia_._rosner/profile.jpeg').default,
                    size: 1987654987,
                    created: randomDate(),
                    modified: randomDate(),
                    accessed: randomDate()
                },
                {
                    name: 'ramona_jst-profile.jpg',
                    url: require('@src/assets/images/examples/ramona_jst/profile.jpeg').default,
                    size: 1987654987,
                    created: randomDate(),
                    modified: randomDate(),
                    accessed: randomDate()
                },
                {
                    name: 'ramona_jst-1.jpg',
                    url: require('@src/assets/images/examples/ramona_jst/1.jpeg').default,
                    size: 1987654987,
                    created: randomDate(),
                    modified: randomDate(),
                    accessed: randomDate()
                },
                {
                    name: 'ramona_jst-2.jpg',
                    url: require('@src/assets/images/examples/ramona_jst/2.jpeg').default,
                    size: 1987654987,
                    created: randomDate(),
                    modified: randomDate(),
                    accessed: randomDate()
                },
                {
                    name: 'ramona_jst-3.jpg',
                    url: require('@src/assets/images/examples/ramona_jst/3.jpeg').default,
                    size: 1987654987,
                    created: randomDate(),
                    modified: randomDate(),
                    accessed: randomDate()
                },
                {
                    name: 'ramona_jst-4.jpg',
                    url: require('@src/assets/images/examples/ramona_jst/4.jpeg').default,
                    size: 1987654987,
                    created: randomDate(),
                    modified: randomDate(),
                    accessed: randomDate()
                },
                {
                    name: 'ramona_jst-5.jpg',
                    url: require('@src/assets/images/examples/ramona_jst/5.jpeg').default,
                    size: 1987654987,
                    created: randomDate(),
                    modified: randomDate(),
                    accessed: randomDate()
                }
            ]
        },
        {
            name: 'Videos',
            size: randomSize(),
            created: randomDate(),
            modified: randomDate(),
            accessed: randomDate(),
            isDir: true,
            children: [
                {
                    name: 'vid1.mp4',
                    videoId: 'c86c0b205baeafa575607f10fa8e7038',
                    size: randomSize(),
                    created: randomDate(),
                    modified: randomDate(),
                    accessed: randomDate()
                },
                {
                    name: '4K full video.mp4',
                    videoId: 'fad0addde98644df850b00a58bda6dd3',
                    size: randomSize(),
                    created: randomDate(),
                    modified: randomDate(),
                    accessed: randomDate()
                },
                {
                    name: 'some video.avi',
                    videoId: 'c86c0b205baeafa575607f10fa8e7038',
                    size: randomSize(),
                    created: randomDate(),
                    modified: randomDate(),
                    accessed: randomDate()
                },
                {
                    name: 'vid2.mp4',
                    videoId: 'fad0addde98644df850b00a58bda6dd3',
                    size: randomSize(),
                    created: randomDate(),
                    modified: randomDate(),
                    accessed: randomDate()
                },
                {
                    name: 'vid3.mp4',
                    videoId: 'c86c0b205baeafa575607f10fa8e7038',
                    size: randomSize(),
                    created: randomDate(),
                    modified: randomDate(),
                    accessed: randomDate()
                },
                {
                    name: 'vid4.mp4',
                    videoId: 'fad0addde98644df850b00a58bda6dd3',
                    size: randomSize(),
                    created: randomDate(),
                    modified: randomDate(),
                    accessed: randomDate()
                },
                {
                    name: 'vid5.mp4',
                    videoId: 'c86c0b205baeafa575607f10fa8e7038',
                    size: randomSize(),
                    created: randomDate(),
                    modified: randomDate(),
                    accessed: randomDate()
                },
                {
                    name: 'vid6.mp4',
                    videoId: 'fad0addde98644df850b00a58bda6dd3',
                    size: randomSize(),
                    created: randomDate(),
                    modified: randomDate(),
                    accessed: randomDate()
                },
                {
                    name: 'vid7.mp4',
                    videoId: 'c86c0b205baeafa575607f10fa8e7038',
                    size: randomSize(),
                    created: randomDate(),
                    modified: randomDate(),
                    accessed: randomDate()
                },
                {
                    name: 'vid8.mp4',
                    videoId: 'fad0addde98644df850b00a58bda6dd3',
                    size: randomSize(),
                    created: randomDate(),
                    modified: randomDate(),
                    accessed: randomDate()
                },
                {
                    name: 'vid9.mp4',
                    videoId: 'c86c0b205baeafa575607f10fa8e7038',
                    size: randomSize(),
                    created: randomDate(),
                    modified: randomDate(),
                    accessed: randomDate()
                },
                {
                    name: 'vid10.mp4',
                    videoId: 'fad0addde98644df850b00a58bda6dd3',
                    size: randomSize(),
                    created: randomDate(),
                    modified: randomDate(),
                    accessed: randomDate()
                },
                {
                    name: 'vid11.mp4',
                    videoId: 'c86c0b205baeafa575607f10fa8e7038',
                    size: randomSize(),
                    created: randomDate(),
                    modified: randomDate(),
                    accessed: randomDate()
                },
                {
                    name: 'vid12.mp4',
                    videoId: 'fad0addde98644df850b00a58bda6dd3',
                    size: randomSize(),
                    created: randomDate(),
                    modified: randomDate(),
                    accessed: randomDate()
                },
                {
                    name: 'vid13.mp4',
                    videoId: 'c86c0b205baeafa575607f10fa8e7038',
                    size: randomSize(),
                    created: randomDate(),
                    modified: randomDate(),
                    accessed: randomDate()
                },
                {
                    name: 'vid14.mp4',
                    videoId: 'fad0addde98644df850b00a58bda6dd3',
                    size: randomSize(),
                    created: randomDate(),
                    modified: randomDate(),
                    accessed: randomDate()
                },
                {
                    name: 'vid15.mp4',
                    videoId: 'c86c0b205baeafa575607f10fa8e7038',
                    size: randomSize(),
                    created: randomDate(),
                    modified: randomDate(),
                    accessed: randomDate()
                },
                {
                    name: 'vid16.mp4',
                    videoId: 'fad0addde98644df850b00a58bda6dd3',
                    size: randomSize(),
                    created: randomDate(),
                    modified: randomDate(),
                    accessed: randomDate()
                }
            ]
        },
        {
            name: 'Empty folder',
            size: 0,
            created: randomDate(),
            modified: randomDate(),
            accessed: randomDate(),
            isDir: true,
            children: []
        },
        {
            name: 'test-picture.jpg',
            url: require('@src/assets/images/examples/bicknk_stella/profile.jpeg').default,
            size: 1987654987,
            created: randomDate(),
            modified: randomDate(),
            accessed: randomDate()
        },
        {
            name: 'document.doc',
            size: randomSize(),
            created: randomDate(),
            modified: randomDate(),
            accessed: randomDate()
        },
        {
            name: 'document.pdf',
            size: randomSize(),
            created: randomDate(),
            modified: randomDate(),
            accessed: randomDate()
        },
        {
            name: 'wirus.exe',
            size: randomSize(),
            created: randomDate(),
            modified: randomDate(),
            accessed: randomDate()
        },
        {
            name: 'letter.txt',
            size: randomSize(),
            created: randomDate(),
            modified: randomDate(),
            accessed: randomDate()
        }
    ]
}

mock.onGet('/user/packages').reply(() => [200, data.packages])
mock.onGet('/user/folders').reply(() => [200, data.folders])
mock.onGet('/user/files').reply(() => [200, filesData])

mock.onGet('/user/files').reply(() => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve([200, filesData])
        }, RESPONSE_DELAY)
    })
})

mock.onGet('/user/files/download').reply(({ data }) => {
    const { files } = JSON.parse(data)
    console.log('Downloading files', files)
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve([200, filesData])
        }, RESPONSE_DELAY)
    })
})

mock.onPost('/user/files/rename').reply(({ data }) => {
    const { filePath, name } = JSON.parse(data)
    console.log(`Renaming "${filePath}" to "${name}"`)
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve([
                200,
                {
                    fileTree: filesData.fileTree.map((d, i) => (i !== 0 ? d : { ...d, name }))
                }
            ])
        }, RESPONSE_DELAY)
    })
})

mock.onPost('/user/files/move').reply(({ data }) => {
    const { filePaths, newPath } = JSON.parse(data)
    console.log(`Moving "${filePaths}" to "${newPath}"`)
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve([
                200,
                {
                    fileTree: filesData.fileTree.filter((d) => {
                        return `/${d.name}` !== filePaths[0]
                    })
                }
            ])
        }, RESPONSE_DELAY)
    })
})
