import mock from '../mock'

const data = {
    scheduledEventCategories: [
        {
            colorCode: '',
            title: 'Story'
        },
        {
            colorCode: '',
            title: 'Beitrag'
        },
        {
            colorCode: '',
            title: 'Nachricht'
        }
    ],
    scheduledEvents: [
        {
            weekday: 'Do',
            day: '4',
            title: 'Exklusiv Video',
            subtitle: '1 [foto_icon]; 1 [Video]; Für Fans',
            amount: '40',
            currency: '€',
            category: 'story',
            avatar: require('@src/assets/images/avatars/avatar-blank.png').default
        },
        {
            weekday: 'Fr',
            day: '5',
            title: 'Neues Video',
            subtitle: '1 [foto_icon]; 1 [Video]; Für Fans',
            amount: '40',
            currency: '€',
            category: 'post',
            avatar: require('@src/assets/images/avatars/avatar-blank.png').default
        },
        {
            weekday: 'Sa',
            day: '6',
            title: 'Weekend Special',
            subtitle: '1 [foto_icon]; 1 [Video]; Für Fans',
            amount: '40',
            currency: '€',
            category: 'message',
            avatar: require('@src/assets/images/avatars/avatar-blank.png').default
        }
    ],
    currentEvents: [
        {
            title: 'Abo-Preis und Werbeaktionen',
            subtitle: '18 € pro Monat, 2 Abo Pakete'
        },
        {
            title: 'Aktuelle Angebote',
            subtitle: '60 % Rabatt für 28 Tage für max. 100 Benutzer neue Abonnenten endet 05.07.'
        }
    ]
}

mock.onGet('/scheduledEvents').reply(() => [200, data.scheduledEvents])
mock.onGet('/scheduledEventCategories').reply(() => [200, data.scheduledEventCategories])
mock.onGet('/currentEvents').reply(() => [200, data.currentEvents])
