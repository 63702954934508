import mock from '../mock'

const RESPONSE_DELAY = 700 //ms

const data = {
    savedPosts: [
        {
            avatar: require('@src/assets/images/examples/nastjahale/profile.jpeg').default,
            username: 'nastjahale',
            postTime: '12 Dec 2018 at 1:16 AM',
            postText:
                'Wonderful Machine· A well-written bio allows viewers to get to know a photographer beyond the work. This can make the difference when presenting to clients who are looking for the perfect fit.',
            postImg: require('@src/assets/images/profile/post-media/2.jpg').default,
            likes: '1.25k',
            youLiked: false,
            saved: true,
            comments: '1.25k',
            share: '1.25k',
            likedUsers: [
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'Trine Lynes'
                },
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'Lilian Nenes'
                },
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'Alberto Glotzbach'
                },
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'George Nordic'
                },
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'Vinnie Mostowy'
                }
            ],
            likedCount: 140,
            detailedComments: [
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'Kitty Allanson',
                    comment: 'Easy & smart fuzzy search🕵🏻 functionality which enables users to search quickly.',
                    commentsLikes: 34,
                    youLiked: false
                },
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'Jackey Potter',
                    comment: 'Unlimited color🖌 options allows you to set your application color as per your branding 🤪.',
                    commentsLikes: 61,
                    youLiked: true
                },
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'Kitty Allanson',
                    comment:
                        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at nisl fringilla, aliquam metus in, cursus risus. Donec eget lacinia augue. In hac habitasse platea dictumst.',
                    commentsLikes: 5,
                    youLiked: false
                },
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'Jackey Potter',
                    comment: 'Phasellus sem nisi, tempor id libero ultricies, venenatis rhoncus orci. Sed vel magna sit amet dolor molestie tincidunt non quis sem.',
                    commentsLikes: 1,
                    youLiked: false
                },
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'Kitty Allanson',
                    comment: 'Cras vel metus erat. Ut ex enim, scelerisque eu dui vitae, sagittis semper enim.',
                    commentsLikes: 46,
                    youLiked: false
                },
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'Jackey Potter',
                    comment: 'Unlimited color🖌 options allows you to set your application color as per your branding 🤪.',
                    commentsLikes: 84,
                    youLiked: false
                },
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'Kitty Allanson',
                    comment: 'Easy & smart fuzzy search🕵🏻 functionality which enables users to search quickly.',
                    commentsLikes: 3,
                    youLiked: false
                },
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'Jackey Potter',
                    comment: 'Unlimited color🖌 options allows you to set your application color as per your branding 🤪.',
                    commentsLikes: 123,
                    youLiked: true
                },
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'Kitty Allanson',
                    comment: 'Easy & smart fuzzy search🕵🏻 functionality which enables users to search quickly.',
                    commentsLikes: 34,
                    youLiked: true
                },
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'Jackey Potter',
                    comment: 'Unlimited color🖌 options allows you to set your application color as per your branding 🤪.',
                    commentsLikes: 61,
                    youLiked: true
                }
            ]
        },
        {
            avatar: require('@src/assets/images/examples/nastjahale/profile.jpeg').default,
            username: 'nastjahale',
            postTime: '11 Dec 2019 at 1:16 AM',
            postText:
                'Wonderful Machine· A well-written bio allows viewers to get to know a photographer beyond the work. This can make the difference when presenting to clients who are looking for the perfect fit.',
            postImg: require('@src/assets/images/profile/post-media/25.jpg').default,
            likes: '1.25k',
            youLiked: true,
            saved: true,
            comments: '1.25k',
            share: '1.25k',
            likedUsers: [
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'Kori Scargle'
                },
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'Florinda Mollison'
                },
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'Beltran Endley'
                },
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'Kara Gerred'
                },
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'Sophey Bru'
                }
            ],
            likedCount: 271,
            detailedComments: [
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'Kitty Allanson',
                    comment: 'Easy & smart fuzzy search🕵🏻 functionality which enables users to search quickly.',
                    commentsLikes: 34,
                    youLiked: false
                }
            ]
        },
        {
            avatar: require('@src/assets/images/examples/nastjahale/profile.jpeg').default,
            username: 'nastjahale',
            postTime: '12 Dec 2019 at 1:16 AM',
            postText:
                'Wonderful Machine· A well-written bio allows viewers to get to know a photographer beyond the work. This can make the difference when presenting to clients who are looking for the perfect fit.',
            videoThumbnail: 'https://customer-99ap0bwekmgnkbj9.cloudflarestream.com/fad0addde98644df850b00a58bda6dd3/thumbnails/thumbnail.jpg',
            videoId: 'fad0addde98644df850b00a58bda6dd3',
            likes: '1.25k',
            youLiked: true,
            saved: true,
            comments: '1.25k',
            share: '1.25k',
            likedUsers: [
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'Dehlia Bolderson'
                },
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'De Lamy'
                },
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'Vallie Kingsley'
                },
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'Nadia Armell'
                },
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'Romonda Aseef'
                }
            ],
            likedCount: 264,
            detailedComments: [
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'Kitty Allanson',
                    comment: 'Easy & smart fuzzy search🕵🏻 functionality which enables users to search quickly.',
                    commentsLikes: 34,
                    youLiked: false
                }
            ]
        },
        {
            avatar: require('@src/assets/images/examples/nastjahale/profile.jpeg').default,
            username: 'nastjahale',
            postTime: '12 Dec 2018 at 1:16 AM',
            postText:
                'Wonderful Machine· A well-written bio allows viewers to get to know a photographer beyond the work. This can make the difference when presenting to clients who are looking for the perfect fit.',
            postImg: require('@src/assets/images/profile/user-uploads/user-10.jpg').default,
            likes: '1.25k',
            youLiked: true,
            saved: true,
            comments: '1.25k',
            share: '1.25k',
            likedUsers: [
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'Trine Lynes'
                },
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'Lilian Nenes'
                },
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'Alberto Glotzbach'
                },
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'George Nordic'
                },
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'Vinnie Mostowy'
                }
            ],
            likedCount: 140,
            detailedComments: [
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'Kitty Allanson',
                    comment: 'Easy & smart fuzzy search🕵🏻 functionality which enables users to search quickly.',
                    commentsLikes: 34,
                    youLiked: false
                },
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'Jackey Potter',
                    comment: 'Unlimited color🖌 options allows you to set your application color as per your branding 🤪.',
                    commentsLikes: 61,
                    youLiked: true
                }
            ]
        },
        {
            avatar: require('@src/assets/images/examples/nastjahale/profile.jpeg').default,
            username: 'nastjahale',
            postTime: '12 Dec 2019 at 1:16 AM',
            postText:
                'Wonderful Machine· A well-written bio allows viewers to get to know a photographer beyond the work. This can make the difference when presenting to clients who are looking for the perfect fit.',
            videoThumbnail: 'https://customer-99ap0bwekmgnkbj9.cloudflarestream.com/c86c0b205baeafa575607f10fa8e7038/thumbnails/thumbnail.jpg',
            videoId: 'c86c0b205baeafa575607f10fa8e7038',
            likes: '1.25k',
            youLiked: true,
            saved: true,
            comments: '1.25k',
            share: '1.25k',
            likedUsers: [
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'Dehlia Bolderson'
                },
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'De Lamy'
                },
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'Vallie Kingsley'
                },
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'Nadia Armell'
                },
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'Romonda Aseef'
                }
            ],
            likedCount: 264,
            detailedComments: [
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'Kitty Allanson',
                    comment: 'Easy & smart fuzzy search🕵🏻 functionality which enables users to search quickly.',
                    commentsLikes: 34,
                    youLiked: false
                }
            ]
        },
        {
            avatar: require('@src/assets/images/examples/nastjahale/profile.jpeg').default,
            username: 'nastjahale',
            postTime: '11 Dec 2019 at 1:16 AM',
            postText:
                'Wonderful Machine· A well-written bio allows viewers to get to know a photographer beyond the work. This can make the difference when presenting to clients who are looking for the perfect fit.',
            postImg: require('@src/assets/images/profile/user-uploads/user-02.jpg').default,
            likes: '1.25k',
            youLiked: true,
            saved: true,
            comments: '1.25k',
            share: '1.25k',
            likedUsers: [
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'Kori Scargle'
                },
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'Florinda Mollison'
                },
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'Beltran Endley'
                },
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'Kara Gerred'
                },
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'Sophey Bru'
                }
            ],
            likedCount: 271,
            detailedComments: [
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'Kitty Allanson',
                    comment: 'Easy & smart fuzzy search🕵🏻 functionality which enables users to search quickly.',
                    commentsLikes: 34,
                    youLiked: false
                }
            ]
        },
        {
            avatar: require('@src/assets/images/examples/nastjahale/profile.jpeg').default,
            username: 'nastjahale',
            postTime: '12 Dec 2018 at 1:16 AM',
            postText:
                'Wonderful Machine· A well-written bio allows viewers to get to know a photographer beyond the work. This can make the difference when presenting to clients who are looking for the perfect fit.',
            postImg: require('@src/assets/images/profile/user-uploads/user-11.jpg').default,
            likes: '1.25k',
            youLiked: true,
            saved: true,
            comments: '1.25k',
            share: '1.25k',
            likedUsers: [
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'Trine Lynes'
                },
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'Lilian Nenes'
                },
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'Alberto Glotzbach'
                },
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'George Nordic'
                },
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'Vinnie Mostowy'
                }
            ],
            likedCount: 140,
            detailedComments: [
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'Kitty Allanson',
                    comment: 'Easy & smart fuzzy search🕵🏻 functionality which enables users to search quickly.',
                    commentsLikes: 34,
                    youLiked: false
                },
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'Jackey Potter',
                    comment: 'Unlimited color🖌 options allows you to set your application color as per your branding 🤪.',
                    commentsLikes: 61,
                    youLiked: true
                }
            ]
        },
        {
            avatar: require('@src/assets/images/examples/nastjahale/profile.jpeg').default,
            username: 'nastjahale',
            postTime: '11 Dec 2019 at 1:16 AM',
            postText:
                'Wonderful Machine· A well-written bio allows viewers to get to know a photographer beyond the work. This can make the difference when presenting to clients who are looking for the perfect fit.',
            postImg: require('@src/assets/images/profile/user-uploads/user-05.jpg').default,
            likes: '1.25k',
            youLiked: true,
            saved: true,
            comments: '1.25k',
            share: '1.25k',
            likedUsers: [
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'Kori Scargle'
                },
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'Florinda Mollison'
                },
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'Beltran Endley'
                },
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'Kara Gerred'
                },
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'Sophey Bru'
                }
            ],
            likedCount: 271,
            detailedComments: [
                {
                    avatar: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'Kitty Allanson',
                    comment: 'Easy & smart fuzzy search🕵🏻 functionality which enables users to search quickly.',
                    commentsLikes: 34,
                    youLiked: false
                }
            ]
        }
    ]
}

mock.onGet('/profile/savedPosts').reply(() => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve([200, data.savedPosts])
        }, RESPONSE_DELAY)
    })
})
