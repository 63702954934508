import mock from '../mock'

const stories1 = [
    {
        url: '/static/media/profile.4472ac5d.jpeg'
    },
    {
        url: '/static/media/profile.be6f0825.jpeg'
    },
    {
        url: '/static/media/profile.4472ac5d.jpeg'
    },
    {
        url: '/static/media/profile.be6f0825.jpeg'
    }
]
const stories2 = [
    {
        url: '/static/media/profile.be6f0825.jpeg'
    },
    {
        url: '/static/media/profile.44c05ab8.jpeg'
    },
    {
        url: '/static/media/profile.be6f0825.jpeg'
    }
]
const stories3 = [
    {
        url: '/static/media/profile.44c05ab8.jpeg'
    },
    {
        url: '/static/media/profile.be6f0825.jpeg'
    },
    {
        url: '/static/media/profile.44c05ab8.jpeg'
    },
    {
        url: '/static/media/profile.be6f0825.jpeg'
    },
    {
        url: '/static/media/profile.44c05ab8.jpeg'
    },
    {
        url: '/static/media/profile.be6f0825.jpeg'
    },
    {
        url: '/static/media/profile.4472ac5d.jpeg'
    },
    {
        url: '/static/media/profile.be6f0825.jpeg'
    }
]

mock.onGet('/profile/stories').reply((config) => {
    const { user } = config
    switch (user) {
        case 'bicknk_stella':
            return [200, stories1]
        case 'emilia_._rosner':
            return [200, stories2]
        default:
            return [200, stories3]
    }
})
