import mock from '../mock'

const data = {
    promotions: [
        {
            img_url: require('@src/assets/images/examples/bicknk_stella/profile.jpeg').default,
            title: 'Begrenztes Angebot - 60% Rabatt für 28 Tage',
            description:
                'Dies hier ist ein Beispieltext über fünfzig Wörter. Es dient zur Anschauung von Textlängen. Denn nicht jeder Shopbetreiber oder Webdesginer hat eine genaue Vorstellung davon, wie ein Textumfang auf einer Webseite wirkt. Textlängen mit bis zu fünfzig Wörtern diesen meist als Kurzbeschreibung oder als Teaser-Text, der neugierig auf mehr macht.',
            target_users_text: 'Für neue Abonenten',
            start_data: '10. Juni 2022, 15:00 Uhr',
            can_use_count: 50,
            used_count: 0
        },
        {
            img_url: require('@src/assets/images/examples/emilia_._rosner/profile.jpeg').default,
            title: 'Begrenztes Angebot - 20% Rabatt für 10 Tage',
            description:
                'Dies hier ist ein Beispieltext über fünfzig Wörter. Es dient zur Anschauung von Textlängen. Denn nicht jeder Shopbetreiber oder Webdesginer hat eine genaue Vorstellung davon, wie ein Textumfang auf einer Webseite wirkt. Textlängen mit bis zu fünfzig Wörtern diesen meist als Kurzbeschreibung oder als Teaser-Text, der neugierig auf mehr macht.',
            target_users_text: 'Für neue Abonenten',
            start_data: '10. Juli 2022, 15:00 Uhr',
            can_use_count: 10,
            used_count: 0
        }
    ]
}

mock.onGet('/promotions').reply(() => [200, data.promotions])
