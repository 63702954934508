import mock from '../mock'

const data = {
    notifications: [
        {
            label: 'Abos',
            count: 4,
            items: [
                {
                    avatar_img: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'nastjahale',
                    displayd_name: 'Nastja Hale',
                    text: 'has purchased your post for $50!',
                    timestamp: '2019-04-03T21:00:00+00:00',
                    type: 'success'
                },
                {
                    avatar_img: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'nastjahale',
                    displayd_name: 'Nastja Hale',
                    text: 'has purchased your post for $50!',
                    timestamp: '2023-04-03T21:00:00+00:00',
                    type: 'success'
                },
                {
                    avatar_img: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'nastjahale',
                    displayd_name: 'Nastja Hale',
                    text: 'has purchased your post for $50!',
                    timestamp: '2019-04-03T21:00:00+00:00',
                    type: 'success'
                },
                {
                    avatar_img: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'nastjahale',
                    displayd_name: 'Nastja Hale',
                    text: 'has purchased your post for $50!',
                    timestamp: '2022-04-03T21:00:00+00:00',
                    type: 'success'
                }
            ]
        },
        {
            label: 'Verkäufe',
            count: 2,
            items: [
                {
                    avatar_img: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'nastjahale',
                    displayd_name: 'Nastja Hale',
                    text: 'has purchased your post for $50!',
                    timestamp: '2019-04-03T21:00:00+00:00',
                    type: 'primary'
                },
                {
                    avatar_img: require('@src/assets/images/avatars/avatar-blank.png').default,
                    username: 'nastjahale',
                    displayd_name: 'Nastja Hale',
                    text: 'has purchased your post for $50!',
                    timestamp: '2022-04-03T21:00:00+00:00',
                    type: 'primary'
                }
            ]
        },
        {
            label: 'Tips',
            count: 0,
            items: []
        }
    ],
    totalUnreadNotificationsCount: 10
}

mock.onGet('/notifications').reply((config) => {
    const filters = config.filters

    return [200, filters ? data.notifications.filter((item) => filters.includes(item.label)) : data.notifications]
})

mock.onGet('/notifications/filters').reply(() => [
    200,
    data.notifications.map((item) => {
        return {
            label: item.label,
            count: item.count
        }
    })
])

mock.onGet('/notifications/totalUnreadNotificationsCount').reply(() => [200, data.totalUnreadNotificationsCount])

mock.onGet('/notifications/totalUnreadNotificationsCount').reply(() => [200, '10'])
mock.onGet('/notifications/totalUnreadNotificationsColor').reply(() => [200, 'primary'])
